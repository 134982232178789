import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export const Testimonials = (props) => {
  const [aboutData, setAboutData] = useState(null);
  const [aboutDataHeader, setAboutDataHeader] = useState([]); // Initialize as empty array
  const URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
  const fetchAboutData = async () => { 
    const response = await fetch(URL +`/api/testimonial`);
    const data = await response.json();
    console.log("data tarik", data);
    setAboutData(data);  // Assuming the API returns an array, get the first object
  };
  const servicesHeader = async () => {
    const response = await fetch(URL +`/api/testimonialHeader`);
    const data = await response.json();
    console.log("headerS", data[0].paragraph);

    setAboutDataHeader(data[0].paragraph)

  };

  // Call the functions
  fetchAboutData();
  servicesHeader();
}, []);
  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>
  <span style={{ color: 'orange' }}>Client </span> 
  <span style={{ color: '#2e649d' }}>Croyons</span>
</h2>
          <h3>{aboutDataHeader ? aboutDataHeader : "loading..."}</h3>
        </div>
        <div className="row">
        <div >
    <div >
      

      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={30}
        slidesPerView={4}
        // navigation
        pagination={{ clickable: true }}
        autoplay={{
          delay: 3000, // Ganti waktu per slide (ms)
          disableOnInteraction: false, // Autoplay tidak berhenti saat ada interaksi
        }}
        breakpoints={{
          320: { slidesPerView: 1 },
          768: { slidesPerView: 2 },
          1024: { slidesPerView: 3 },
          1200: { slidesPerView: 4 },
        }}
        style={{
          width: '100%',
          maxWidth: '1200px',
          margin: '0 auto',
          height: '33rem'
        }}
      >
        {aboutData !== null
          ? aboutData.map((d, i) => (
              <SwiperSlide key={`${d.name}-${i}`} style={{ display: 'flex', justifyContent: 'center' }}>
                <div
                  className="thumbnail"
                  style={{
                    width: '100%',
                    maxWidth: '300px',
                    textAlign: 'center',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    borderRadius: '8px',
                    overflow: 'hidden',
                  }}
                >
                  <img
                    src={`${URL}${d.img}`}
                    alt={d.name}
                    style={{
                      width: '100%',
                      height: '300px',
                      objectFit: 'cover',
                      display: 'block',
                    }}
                  />
                  <div className="caption" style={{ padding: '10px' }}>
                    <h4 style={{ margin: '10px 0', fontSize: '18px' }}>{d.name}</h4>
                    <p style={{ color: '#555', fontSize: '14px' }}>{d.job}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))
          : 'loading'}
      </Swiper>
    </div>
  </div>
        
        </div>
      </div>
    </div>
  );
};
