import React, { useState, useEffect } from 'react';
import styles from './css/Header.module.css'; // Pastikan CSS sudah ada
import { useNavigate } from "react-router-dom";

const Services = () => {
  const URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [headers, setHeaders] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    paragraph: '',
    img: null
  });
  const [previewImage, setPreviewImage] = useState(null); // Untuk menampilkan preview gambar
  const [editing, setEditing] = useState(null);

  // Fetch data header
  useEffect(() => {
    checkAuth();
    loadData();
  }, []);
  const checkAuth =() => {
    fetch(URL + `/api/aboutdetail/cek`,{
        headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "Invalid or expired token"){
            console.log("wkwkwkkw");
            navigate("/admin");
        }
      })
      .catch((error) => console.log(error));
  }
  const loadData = () => {
    fetch(URL + '/api/team')
    .then((response) => response.json())
    .then((data) => setHeaders(data))
    .catch((error) => console.log(error));
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prev) => ({ ...prev, img: file }));

    // Menampilkan preview gambar
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append('title', formData.title);
    form.append('paragraph', formData.paragraph);

    // Jika ada gambar yang dipilih, gunakan gambar baru
    if (formData.img) {
      form.append('img', formData.img);
    } else {
      // Jika tidak ada gambar baru, kirim gambar yang sudah ada
      form.append('img', headers.find((header) => header.id === editing)?.img);
    }

    const url = editing
      ? URL + `/api/team/${editing}`
      : URL + '/api/team';
    const method = editing ? 'PUT' : 'POST';

    fetch(url, {
      method: method,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
      body: form,
    })
      .then((response) => response.json())
      .then((data) => {
        loadData();
        if (editing) {
          setHeaders(headers.map((header) => (header.id === editing ? { ...header, ...formData } : header)));
          setEditing(null);
        } else {
          // setHeaders([...headers, data.data]);
        }
        setFormData({ title: '', paragraph: '', img: null });
        setPreviewImage(null); // Clear preview after submission
      })
      .catch((error) => console.log(error));
  };

  const handleEdit = (id) => {
    const header = headers.find((header) => header.id === id);
    setFormData({
      title: header.title,
      paragraph: header.paragraph,
      img: null // Reset file input when editing
    });
    setPreviewImage(URL + header.img); // Set the current image as preview
    setEditing(id);
  };

  const handleDelete = (id) => {
    const isConfirmed = window.confirm("Apakah Anda yakin ingin menghapus data ini?");
    
    if (isConfirmed) {

      fetch(URL + `/api/team/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setHeaders(headers.filter((header) => header.id !== id));
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Solusi & layanan CROYONS</h1>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formGroup}>
          <label htmlFor="title">Title</label>
          <input
            type="text"
            name="title"
            id="title"
            value={formData.title}
            onChange={handleChange}
            placeholder="Enter title"
            className={styles.input}
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="paragraph">Paragraph</label>
          <textarea
            name="paragraph"
            id="paragraph"
            value={formData.paragraph}
            onChange={handleChange}
            placeholder="Enter paragraph"
            className={styles.textarea}
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="img">Image</label>
          <input
            type="file"
            name="img"
            id="img"
            onChange={handleFileChange}
            className={styles.fileInput}
          />
          {previewImage && (
            <div className={styles.previewContainer}>
              <h4>Image Preview:</h4>
              <img src={previewImage} alt="Preview" className={styles.previewImage} />
            </div>
          )}
        </div>
        <button
        type="submit" className={styles.submitButton}>
          {editing ? 'Update Header' : 'Add Header'}
        </button>
        {/* {editing !== null &&
        } */}
      </form>

      <h3 className={styles.subheader}>Existing Data </h3>
      <ul className={styles.list}>
        {headers.map((header) => (
          <li key={header.id} className={styles.listItem}>
            <img src={URL + header.img} alt="Header" className={styles.image} />
            <div className={styles.textContainer}>
              <h4 className={styles.listTitle}>{header.title}</h4>
              <p className={styles.listParagraph}>{header.paragraph}</p>
            </div>
            <div className={styles.actions}>
              <button
                onClick={() => handleEdit(header.id)}
                className={`${styles.actionButton} ${styles.editButton}`}
              >
                Edit
              </button>
              <button
                onClick={() => handleDelete(header.id)}
                className={`${styles.actionButton} ${styles.deleteButton}`}
              >
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Services;
