import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
export const Team = () => {
  const [aboutData, setAboutData] = useState(null);
  const URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Fetch the about data (paragraph)
    const fetchAboutData = async () => { 
      const response = await fetch(URL +`/api/team`);
      const data = await response.json();
      console.log("data tarik", data);
      setAboutData(data);  // Assuming the API returns an array, get the first object
    };
    fetchAboutData();
  }, []);
  return (
    <div id="team" style={{ textAlign: 'center', padding: '50px 0' }}>
    <div className="container">
      <div className="col-md-8 col-md-offset-2 section-title" style={{ marginBottom: '40px' }}>
        <h2>
          <span style={{ color: 'orange' }}>TEAM </span>
          <span style={{ color: '#2e649d' }}>KAMI</span>
        </h2>
      </div>

      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={30}
        slidesPerView={4}
        // navigation
        pagination={{ clickable: true }}
        autoplay={{
          delay: 3000, // Ganti waktu per slide (ms)
          disableOnInteraction: false, // Autoplay tidak berhenti saat ada interaksi
        }}
        breakpoints={{
          320: { slidesPerView: 1 },
          768: { slidesPerView: 2 },
          1024: { slidesPerView: 3 },
          1200: { slidesPerView: 4 },
        }}
        style={{
          width: '100%',
          maxWidth: '1200px',
          margin: '0 auto',
          height: '33rem'
        }}
      >
        {aboutData !== null
          ? aboutData.map((d, i) => (
              <SwiperSlide key={`${d.name}-${i}`} style={{ display: 'flex', justifyContent: 'center' }}>
                <div
                  className="thumbnail"
                  style={{
                    width: '100%',
                    maxWidth: '300px',
                    textAlign: 'center',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    borderRadius: '8px',
                    overflow: 'hidden',
                  }}
                >
                  <img
                    src={`${URL}${d.img}`}
                    alt={d.name}
                    style={{
                      width: '100%',
                      height: '300px',
                      objectFit: 'cover',
                      display: 'block',
                    }}
                  />
                  <div className="caption" style={{ padding: '10px' }}>
                    <h4 style={{ margin: '10px 0', fontSize: '18px' }}>{d.name}</h4>
                    <p style={{ color: '#555', fontSize: '14px' }}>{d.job}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))
          : 'loading'}
      </Swiper>
    </div>
  </div>
  );
};
