import React, { useState, useEffect } from "react";

export const Features = () => {
  const URL = process.env.REACT_APP_API_URL;

  const [aboutData, setAboutData] = useState(null);

  useEffect(() => {
    // Fetch the about data (features)
    const fetchAboutData = async () => { 
      const response = await fetch(URL + `/api/features`);
      const data = await response.json();
      setAboutData(data);  // Assuming the API returns an array of objects
      
    };
    fetchAboutData();
  }, []);

  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2><span style={{ color: 'orange' }}>Fitur </span> 
          <span style={{ color: '#2e649d' }}>Kami</span></h2>
        </div>
        <div className="row">
          {/* Check if aboutData is not null and is an array before rendering */}
          {aboutData  !== null
            ? aboutData.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-xs-6 col-md-4">
                  <img src={`${URL}${d.img}`} alt={d.title} />
                  <h3>{d.title}</h3>
                  <p>{d.paragraph}</p>
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
