// src/components/Blog.js
import React, { useState, useEffect } from 'react';
import styles from './css/Header.module.css'; // Pastikan CSS sudah ada
import { useNavigate } from "react-router-dom";
import ReactQuill from 'react-quill'; // Import ReactQuill
import 'react-quill/dist/quill.snow.css'; // Import default styling untuk Quill

const Blog = () => {
  const URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [headers, setHeaders] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    paragraph: '',
    img: null,
    paragraphdetail: '',
    createAt: '' // Menambahkan field createAt
  });
  const [previewImage, setPreviewImage] = useState(null); // Untuk menampilkan preview gambar
  const [editing, setEditing] = useState(null);

  // Fetch data blog
  useEffect(() => {
    checkAuth();
    loadData();
  }, []);

  const checkAuth = () => {
    fetch(URL + `/api/aboutdetail/cek`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "Invalid or expired token") {
          console.log("Token expired");
          navigate("/admin");
        }
      })
      .catch((error) => console.log(error));
  };

  const loadData = () => {
    fetch(URL + '/api/blog')
      .then((response) => response.json())
      .then((data) => setHeaders(data) & console.log("Blog data:", data))
      .catch((error) => console.log(error));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prev) => ({ ...prev, img: file }));

    // Menampilkan preview gambar
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleEditorChange = (value) => {
    setFormData((prev) => ({ ...prev, paragraphdetail: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append('title', formData.title);
    form.append('paragraph', formData.paragraph);
    form.append('paragraphdetail', formData.paragraphdetail);
    form.append('createAt', formData.createAt || new Date().toISOString()); // Gunakan tanggal saat ini jika createAt kosong

    // Jika ada gambar yang dipilih, gunakan gambar baru
    if (formData.img) {
      form.append('img', formData.img);
    } else {
      // Kirimkan gambar yang sudah ada jika tidak ada gambar baru
      form.append('img', headers.find((header) => header.id === editing)?.img);
    }

    const url = editing
      ? URL + `/api/blog/${editing}`
      : URL + '/api/blog';
    const method = editing ? 'PUT' : 'POST';

    fetch(url, {
      method: method,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
      body: form,
    })
      .then((response) => response.json())
      .then((data) => {
        loadData();
        if (editing) {
          setHeaders(headers.map((header) => (header.id === editing ? { ...header, ...formData } : header)));
          setEditing(null);
        } else {
          setHeaders([...headers, data.data]); // Add new header to the list
        }
        setFormData({ title: '', paragraph: '', img: null, paragraphdetail: '', createAt: '' });
        setPreviewImage(null); // Clear preview after submission
      })
      .catch((error) => console.log(error));
  };

  const handleEdit = (id) => {
    const header = headers.find((header) => header.id === id);
    setFormData({
      title: header.title,
      paragraph: header.paragraph,
      img: null, // Reset file input when editing
      paragraphdetail: header.paragraphdetail,
      createAt: header.createAt, // Include createAt field
    });
    setPreviewImage(URL + header.img); // Set the current image as preview
    setEditing(id);
  };

  const handleDelete = (id) => {
    const isConfirmed = window.confirm("Apakah Anda yakin ingin menghapus data ini?");
    
    if (isConfirmed) {
      fetch(URL + `/api/blog/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          loadData();

          setHeaders(headers.filter((header) => header.id !== id));
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Conten Blog CROYONS</h1>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formGroup}>
          <label htmlFor="title">Title</label>
          <input
            type="text"
            name="title"
            id="title"
            value={formData.title}
            onChange={handleChange}
            placeholder="Enter title"
            className={styles.input}
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="paragraph">Paragraph</label>
          <textarea
            name="paragraph"
            id="paragraph"
            value={formData.paragraph}
            onChange={handleChange}
            placeholder="Enter paragraph"
            className={styles.textarea}
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="img">Image</label>
          <input
            type="file"
            name="img"
            id="img"
            onChange={handleFileChange}
            className={styles.fileInput}
          />
          {previewImage && (
            <div className={styles.previewContainer}>
              <h4>Image Preview:</h4>
              <img src={previewImage} alt="Preview" className={styles.previewImage} />
            </div>
          )}
        </div>

        {/* Editor for paragraphdetail */}
        <div className={styles.formGroup}>
          <label htmlFor="paragraphdetail">Paragraph Detail</label>
          <ReactQuill value={formData.paragraphdetail} onChange={handleEditorChange} />
        </div>

        <button type="submit" className={styles.submitButton}>
          {editing ? 'Update Blog' : 'Create Blog'}
        </button>
      </form>

      <div className={styles.blogList}>
        {headers.map((header) => (
          <div key={header.id} className={styles.blogItem}>
            <h3>{header.title}</h3>
            <p>{header.paragraph}</p>
            {header.img && <img src={URL+header.img} alt="Blog" className={styles.blogImage} />}
            <div className={styles.paragraphDetail} dangerouslySetInnerHTML={{ __html: header.paragraphdetail }} />
            <button onClick={() => handleEdit(header.id)} className={styles.editButton}>Edit</button>
            <button onClick={() => handleDelete(header.id)} className={styles.deleteButton}>Delete</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blog;
