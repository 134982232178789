import React, { useState, useEffect } from "react";

export const About = () => {
  const [aboutData, setAboutData] = useState(null);
  const [aboutDataImg, setAboutDataImg] = useState(null);
  const [aboutDetailData, setAboutDetailData] = useState({ Why: [], Why2: [] });

  const URL = process.env.REACT_APP_API_URL;
  useEffect(() => {
    // Fetch the about data (paragraph)
    const fetchAboutData = async () => { 
      const response = await fetch(URL +`/api/header`);
      const data = await response.json();
      console.log(data[0].paragraph,"ini data header")
      setAboutData(data[0].paragraph);  // Assuming the API returns an array, get the first object
      setAboutDataImg(data[0].img);  // Assuming the API returns an array, get the first object
    };

    // Fetch the aboutdetail data (Why and Why2)
    const fetchAboutDetailData = async () => {
      const response = await fetch(URL +`/api/aboutdetail`);
      const data = await response.json();
      const Why = data.filter(item => item.type === "Why").map(item => item.content);
      const Why2 = data.filter(item => item.type === "Why2").map(item => item.content);
      setAboutDetailData({ Why, Why2 });
    };

    // Call the functions
    fetchAboutData();
    fetchAboutDetailData();
  }, []);

  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <img src={`${URL}${aboutDataImg}`} className="img-responsive" alt="" />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2> 
              <span style={{ color: 'orange' }}>Kenapa </span> 
              <span style={{ color: '#2e649d' }}>Kami</span>
              </h2>
              <p>{aboutData ? aboutData : "loading..."}</p>
              {/* <p>{aboutData ? aboutData.paragraph : "loading..."}</p> */}
              <h3>Kenapa Memilih Kami?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {aboutDetailData.Why.length > 0
                      ? aboutDetailData.Why.map((item, index) => (
                          <li key={`${item}-${index}`}>{item}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {aboutDetailData.Why2.length > 0
                      ? aboutDetailData.Why2.map((item, index) => (
                          <li key={`${item}-${index}`}>{item}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
