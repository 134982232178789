import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./View/Home"; // Mengimpor komponen Home yang baru dibuat
import HomeAdmin from "./View/HomeAdmin";
import Header from "./adminComponents/Header";
import About from "./adminComponents/About";
import Gallery from "./adminComponents/Gallery";
import Features from "./adminComponents/Features"
import Services from "./adminComponents/Services"
import Team from "./adminComponents/Team";
import  Testimonial from "./adminComponents/Testimonial"
import LoginForm from "./adminComponents/Login";
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<EmbedHTML />} />
        <Route path="/a" element={<Home />} />
        <Route path="/header" element={<Header />} />
        <Route path="/about" element={<About />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/features" element={<Features />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/testimonial" element={<Testimonial />} />
        <Route path="/team" element={<Team />} />
        <Route path="/admin" element={<LoginForm />} />
        <Route path="/HomeAdmin/*" element={<HomeAdmin />} />
        {/* <Route path="/contoh" element={<RedirectToHTML />} /> */}
      </Routes>
    </Router>
  );
};
function EmbedHTML() {
  return (
    <div>
      <iframe
        src="/home/index.html"
        width="100%"
        height="100%"
        title="contoh.html"
        style={{ border: 'none' }}
      ></iframe>
    </div>
  );
}

export default App;
