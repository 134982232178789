import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import styles from './css/Gallery.module.css'; // Make sure the CSS file is properly linked

const Gallery = () => {
  const navigate = useNavigate();
  const URL = process.env.REACT_APP_API_URL;

  const [gallery, setGallery] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    smallImage: null,
    paragraph: '',
    layanan: '',
    status: '',
    client: '',
    createAt: ''
  });
  const [editing, setEditing] = useState(null);

  useEffect(() => {
    loadData();
    checkAuth();
  }, []);

  // Function to load gallery data
  const loadData = () => {
    fetch(URL + `/api/gallery`)
      .then((response) => response.json())
      .then((data) => {
        setGallery(data);
        console.log("Gallery data loaded", data);
      })
      .catch((error) => console.log("Error fetching gallery data:", error));
  };

  // Function to check if the user is authenticated
  const checkAuth = () => {
    fetch(URL + `/api/aboutdetail/cek`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "Invalid or expired token") {
          console.log("Session expired");
          navigate("/admin");
        }
      })
      .catch((error) => console.log("Error checking authentication:", error));
  };

  // Handle form data input
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle image file change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prev) => ({ ...prev, smallImage: file }));
  };

  // Handle form submission (for both create and update)
const handleSubmit = (e) => {
  e.preventDefault();

  const form = new FormData();
  form.append('smallImage', formData.smallImage || gallery.find(item => item.id === editing)?.smallImage);
  form.append('createAt', formData.createAt); // Add the date to the form data
  form.append('title', formData.title);
  form.append('paragraph', formData.paragraph);
  form.append('layanan', formData.layanan);
  form.append('status', formData.status);
  form.append('client', formData.client);

  // Log form data to see what's inside
  for (let pair of form.entries()) {
    console.log(pair[0] + ': ' + pair[1]);
  }

  const url = editing ? `${URL}/api/gallery/${editing}` : `${URL}/api/gallery`;
  const method = editing ? 'PUT' : 'POST';

  fetch(url, {
    method: method,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
    body: form,
  })
    .then((response) => response.json())
    .then((data) => {
      loadData(); // Refresh gallery data after submission
      if (editing) {
        setGallery((prevGallery) =>
          prevGallery.map((item) =>
            item.id === editing
              ? { ...item, ...formData, smallImage: data.smallImage }
              : item
          )
        );
        setEditing(null); // Reset editing state after update
      } else {
        setGallery((prevGallery) => [...prevGallery, data]);
      }
      setFormData({ title: '', smallImage: null, paragraph: '', layanan: '', status: '', client: '', createAt: '' }); // Clear form
    })
    .catch((error) => console.log("Error submitting form:", error));
};


  // Handle editing an item
  const handleEdit = (id) => {
    const itemToEdit = gallery.find((item) => item.id === id);
    setFormData({
      title: itemToEdit.title,
      smallImage: null, // Reset image input when editing
      paragraph: itemToEdit.paragraph,
      layanan: itemToEdit.layanan,
      status: itemToEdit.status,
      client: itemToEdit.client,
      createAt: formatDate(itemToEdit.createAt) // Set the date as a formatted value
    });
    setEditing(id);
  };

  // Handle deletion of an item
  const handleDelete = (id) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this item?");
    if (isConfirmed) {
      fetch(URL + `/api/gallery/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      })
        .then(() => {
          setGallery((prevGallery) => prevGallery.filter((item) => item.id !== id));
          alert('Gallery deleted successfully');
        })
        .catch((error) => console.log("Error deleting gallery item:", error));
    }
  };

  // Format date to "YYYY-MM-DD"
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Manage Gallery CROYONS</h1>
      <form className={styles.form} onSubmit={handleSubmit}>
        <input
          type="text"
          name="title"
          value={formData.title}
          onChange={handleChange}
          placeholder="Gallery Title"
          className={styles.input}
        />

        {/* Displaying the preview of the image */}
        {(formData.smallImage || editing) && (
          <div className={styles.previewContainer}>
            <img
              src={
                formData.smallImage
                  ? URL + `${formData.smallImage.name}`
                  : URL + gallery.find((item) => item.id === editing)?.smallImage
              }
              alt="Preview"
              className={styles.previewImage}
            />
          </div>
        )}

        {/* Input for paragraph */}
        <textarea
          name="paragraph"
          value={formData.paragraph}
          onChange={handleChange}
          placeholder="Gallery Paragraph"
          className={styles.input}
        />

        {/* Input for layanan */}
        <input
          type="text"
          name="layanan"
          value={formData.layanan}
          onChange={handleChange}
          placeholder="Service"
          className={styles.input}
        />

        {/* Input for status */}
        <input
          type="text"
          name="status"
          value={formData.status}
          onChange={handleChange}
          placeholder="Status"
          className={styles.input}
        />

        {/* Input for client */}
        <input
          type="text"
          name="client"
          value={formData.client}
          onChange={handleChange}
          placeholder="Client"
          className={styles.input}
        />

        {/* Input for createAt (Date) */}
        <input
          type="date"
          name="createAt"
          value={formData.createAt}
          onChange={handleChange}
          className={styles.input}
        />

        {/* Image file input */}
        <input
          type="file"
          name="smallImage"
          onChange={handleFileChange}
          className={styles.input}
        />
        <button type="submit" className={styles.button}>
          {editing ? 'Update Gallery' : 'Add Gallery'}
        </button>
      </form>

      <h3 className={styles.subheader}>Existing Gallery CROYONS</h3>
      <div className={styles.galleryGrid}>
        {gallery.map((item) => (
          <div key={item.id} className={styles.galleryItem}>
            <img
              src={URL + `${item.smallImage}`}
              alt={item.title}
              className={styles.galleryImage}
            />
            <h4 className={styles.title}>{item.title}</h4>
            <h5>Date: {formatDate(item.createAt)}</h5> {/* Displaying the formatted date */}
            <h5>Client: {item.client}</h5>
            <h5>Service: {item.layanan}</h5>
            <h5>Content: {item.paragraph}</h5>
            <h5>Status: {item.status}</h5>
            <div className={styles.actions}>
              <button
                onClick={() => handleEdit(item.id)}
                className={`${styles.button} ${styles.editButton}`}
              >
                Edit
              </button>
              <button
                onClick={() => handleDelete(item.id)}
                className={`${styles.button} ${styles.deleteButton}`}
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
