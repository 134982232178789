import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();

  const [headers, setHeaders] = useState([]);
  const [paragraph, setParagraph] = useState('');
  const [editing, setEditing] = useState(null); // Untuk menandakan apakah kita sedang mengedit

  const URL = process.env.REACT_APP_API_URL;

  // Fetch data header
  useEffect(() => {
    loadData();
    checkAuth();
  }, []);

  const loadData = () => {
    fetch(URL + `/api/about`)
      .then((response) => response.json())
      .then((data) => setHeaders(data))
      .catch((error) => console.log(error));
  }
  const checkAuth =() => {
    fetch(URL + `/api/aboutdetail/cek`,{
        headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "Invalid or expired token"){
            console.log("wkwkwkkw");
            navigate("/admin");
        }
      })
      .catch((error) => console.log(error));
  }

  const handleChange = (e) => {
    setParagraph(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Jika sedang mengedit, lakukan update, jika tidak lakukan create
    const url = editing
      ? URL + `/api/about/${editing}`
      : URL + '/api/about';
    const method = editing ? 'PUT' : 'POST';

    // Kirim data dalam format JSON
    fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
      body: JSON.stringify({ paragraph }),
    })
      .then((response) => response.json())
      .then((data) => {
        loadData();
        if (editing) {
          // Update header yang sudah ada
          setHeaders(headers.map(header => header.id === editing ? { ...header, paragraph } : header));
          setEditing(null); // Reset editing state
        } else {
          // Tambahkan header baru
          setHeaders([...headers, data.data]);
        }
        // setParagraph(''); // Reset form
      })
      .catch((error) => console.log(error));
  };

  const handleEdit = (id) => {
    const header = headers.find((header) => header.id === id);
    setParagraph(header.paragraph); // Set paragraph untuk editing
    setEditing(id); // Set id untuk menandakan kita sedang mengedit
  };

  const handleDelete = (id) => {
    fetch(URL + `/api/about/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setHeaders(headers.filter((header) => header.id !== id));
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      <h1>Manage About CROYONS</h1>
      <form onSubmit={handleSubmit}>
        <textarea
          value={paragraph}
          onChange={handleChange}
          placeholder="Enter paragraph text"
        />
        <button
        disabled={!editing}
        type="submit">{editing ? 'Update About' : 'Update About'}</button>
      </form>

      <h3>Existing About CROYONS</h3>
      <ul>
        {headers.map((header) => (
          <li key={header.id}>
            <p>{header.paragraph}</p>
            <button onClick={() => handleEdit(header.id)}>Edit</button>
            {/* <button onClick={() => handleDelete(header.id)}>Delete</button> */}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default About;
