import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import styles from './css/AboutDetail.module.css'; // Pastikan CSS sudah ada

const AboutDetail = () => {
    const navigate = useNavigate();

  const URL = process.env.REACT_APP_API_URL;

  const [aboutDetails, setAboutDetails] = useState([]);
  const [formData, setFormData] = useState({
    type: '',
    content: ''
  });
  const [editing, setEditing] = useState(null); // Untuk menentukan apakah sedang mengedit

  useEffect(() => {
    loadData();
    checkAuth();
  }, []);

  const loadData = () =>{
    fetch(URL + `/api/aboutdetail`)
      .then((response) => response.json())
      .then((data) => setAboutDetails(data))
      .catch((error) => console.log(error));
  }

 const checkAuth =() => {
    fetch(URL + `/api/aboutdetail/cek`,{
        headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "Invalid or expired token"){
            console.log("wkwkwkkw");
            navigate("/admin");
        }
      })
      .catch((error) => console.log(error));
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const method = editing ? 'PUT' : 'POST';
    const url = editing ? `${URL}/api/aboutdetail/${editing}` : URL +`/api/aboutdetail`;

    fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        loadData();
        if (editing) {
          setAboutDetails(
            aboutDetails.map((item) => (item.id === editing ? { ...item, ...formData } : item))
          );
          setEditing(null);
          loadData();
        } else {
          setAboutDetails([...aboutDetails, data]);
        }
        setFormData({ type: '', content: '' });
      })
      .catch((error) => console.log(error));
  };

  const handleEdit = (id) => {
    const itemToEdit = aboutDetails.find((item) => item.id === id);
    setFormData({ type: itemToEdit.type, content: itemToEdit.content });
    setEditing(id);
  };

  const handleDelete = (id) => {
    const isConfirmed = window.confirm("Apakah Anda yakin ingin menghapus data ini?");
    
    if (isConfirmed) {
      fetch(`${URL}/api/aboutdetail/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json',
        },
      })
        .then(() => {
          setAboutDetails(aboutDetails.filter((item) => item.id !== id));
          alert("Data berhasil dihapus.");
        })
        .catch((error) => {
          console.error(error);
          alert("Terjadi kesalahan saat menghapus data.");
        });
    }
  };
  

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Manage About Details CROYONS</h1>
      
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formGroup}>
          <label htmlFor="type">Type</label>
          <select
            name="type"
            id="type"
            value={formData.type}
            onChange={handleChange}
            className={styles.input}
          >
            <option value="">Select Type</option>
            <option value="Why">Why</option>
            <option value="Why2">Why2</option>
          </select>
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="content">Content</label>
          <textarea
            name="content"
            id="content"
            value={formData.content}
            onChange={handleChange}
            placeholder="Enter content"
            className={styles.textarea}
          />
        </div>

        <button type="submit" className={styles.submitButton}>
          {editing ? 'Update' : 'Add'} About Detail CROYONS
        </button>
      </form>

      <h3 className={styles.subheader}>Existing About Details CROYONS</h3>
      <ul className={styles.list}>
        {aboutDetails.map((item) => (
          <li key={item.id} className={styles.listItem}>
            <div className={styles.textContainer}>
              <h4 className={styles.listTitle}>{item.type}</h4>
              <p className={styles.listContent}>{item.content}</p>
            </div>
            <div className={styles.actions}>
              <button
                onClick={() => handleEdit(item.id)}
                className={`${styles.actionButton} ${styles.editButton}`}
              >
                Edit
              </button>
              <button
                onClick={() => handleDelete(item.id)}
                className={`${styles.actionButton} ${styles.deleteButton}`}
              >
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AboutDetail;
