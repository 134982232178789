import React, { useState, useEffect } from "react";

export const Services = () => {
  const [aboutData, setAboutData] = useState(null);
  const URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Fetch the about data (paragraph)
    const fetchAboutData = async () => { 
      const response = await fetch(URL +`/api/services`);
      const data = await response.json();
      console.log("data tarik", data);
      setAboutData(data);  // Assuming the API returns an array, get the first object
    };
    fetchAboutData();
  }, []);
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
        <h2>
  <span style={{ color: 'orange' }}>Solusi & layanan </span> 
  <span style={{ color: '#2e649d' }}>CROYONS</span>
</h2>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
            dapibus leonec.
          </p> */}
        </div>
        <div className="service-container">
      {aboutData !== null ? (
        aboutData.map((d, i) => (
          <div key={`${d.name}-${i}`} className="service-item">
            <div className="service-desc">
              <img src={`${URL}${d.img}`} alt={d.name} className="img-fluid" />
              <h4>{d.title}</h4>
              <p>{d.paragraph}</p>
            </div>
          </div>
        ))
      ) : (
        <p>Loading...</p>
      )}
    </div>
      </div>
    </div>
  );
};
