import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export const Gallery = () => {
  const [aboutDataHeader, setAboutDataHeader] = useState([]); // Initialize as empty array
  const [aboutData, setAboutData] = useState([]); // Initialize as empty array
  const [currentIndex, setCurrentIndex] = useState(0);
  const URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Fetch the gallery data
    const fetchAboutData = async () => {
      const response = await fetch(URL + `/api/gallery`);
      const data = await response.json();
      console.log("data", data);
      setAboutData(data);  // Set fetched data directly, assuming it's an array
    };
    const galleryHeader = async () => {
      const response = await fetch(URL +`/api/galleryheader`);
      const data = await response.json();
      console.log("headerS", data[0].paragraph);

      setAboutDataHeader(data[0].paragraph)

    };

    // Call the functions
    fetchAboutData();
    galleryHeader();
  }, [URL]);

  const goNext = () => {
    if (aboutData.length > 0) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % aboutData.length); // Loop back to first image
    }
  };

  const goPrev = () => {
    if (aboutData.length > 0) {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + aboutData.length) % aboutData.length); // Loop to last image
    }
  };

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>
            <span style={{ color: "orange" }}>Portofolio </span>
            <span style={{ color: "#2e649d" }}>Web & Aplikasi</span>
          </h2>
          <p>
            {/* {aboutDataHeader !== null ? aboutDataHeader : "Loading..."
            } */}
            {aboutDataHeader ? aboutDataHeader : "loading..."}
           
          </p>
        </div>
        <div className="row">
          <div className="testimonial-slider-container11">
            <div className="testimonial-image-wrapper11">
              <div
                className="testimonial-image11"
                style={{
                  transform: `translateX(-${currentIndex * 100}%)`,
                  transition: "transform 0.3s ease-in-out",
                }}
              >
                {/* Render images if aboutData is available */}
                {aboutData.length > 0 ? (
                  aboutData.map((d, i) => (
                    <div key={i} className="testimonial-item11">
                      <img src={`${URL}${d.smallImage}`} alt={d.title} title={d.title} />
                    </div>
                  ))
                ) : (
                  "Loading..." // Show loading message if data is not fetched yet
                )}
              </div>

              {/* Navigation buttons */}
              <div className="arrow prev" onClick={goPrev}>
                ←
              </div>
              <div className="arrow next" onClick={goNext}>
                →
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
