import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";

const initialState = {
  name: "",
  email: "",
  message: "",
};

export const Contact = (props) => {
  const URL = process.env.REACT_APP_API_URL;
  
  const [aboutDetailData, setAboutDetailData] = useState({
    address: "loading",
    email: "loading",
    facebook: "/",
    instagram: "/",
    phone: "loading",
    twitter: "/",
    youtube: "/",
  });

  useEffect(() => {
    // Fetch the about data (contact details)
    const fetchAboutData = async () => {
      try {
        const response = await fetch(URL + "/api/contact");
        const data = await response.json();
        console.log(data[0], "ini data contact");

        // Set fetched data into state
        setAboutDetailData({
          address: data[0].address || "loading",
          email: data[0].email || "loading",
          facebook: data[0].facebook || "/",
          instagram: data[0].instagram || "/",
          phone: data[0].phone || "loading",
          twitter: data[0].twitter || "/",
          youtube: data[0].youtube || "/",
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetch function
    fetchAboutData();
  }, [URL]);

  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState(initialState);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);

    // Send email using emailjs
    emailjs
      .sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", e.target, "YOUR_PUBLIC_KEY")
      .then(
        (result) => {
          console.log(result.text);
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-12 contact-info">
            <div className="contact-item">
              <h3>Contact Us</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {aboutDetailData.address}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {aboutDetailData.phone}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={aboutDetailData.facebook}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={aboutDetailData.instagram}>
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href={aboutDetailData.twitter}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={aboutDetailData.youtube}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>&copy; Croyons - Let’s Join With Our Digital Circle 2024</p>
        </div>
      </div>
    </div>
  );
};
