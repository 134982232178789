import React, { useState, useEffect } from 'react';
import styles from './css/Header.module.css'; // Pastikan CSS ada
import * as XLSX from 'xlsx';

const Memo = () => {
  const URL1 = process.env.REACT_APP_API_URL;
  const URL = URL1+"/api/memo";
// API endpoint baru

  const [headers, setHeaders] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [item, setItem] = useState('');
  const [paragraph, setParagraph] = useState('');
  const [tlp, setTlp] = useState('');
  const [editing, setEditing] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch data from the API
  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setLoading(true);
    fetch(URL)
      .then((response) => response.json())
      .then((data) => {
        setHeaders(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  // Handle input changes
  const handleChangeName = (e) => setName(e.target.value);
  const handleChangeEmail = (e) => setEmail(e.target.value);
  const handleChangeItem = (e) => setItem(e.target.value);
  const handleChangeParagraph = (e) => setParagraph(e.target.value);
  const handleChangeTlp = (e) => setTlp(e.target.value);

  // Handle form submit (Add or Edit)
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const url = editing ? `${URL}/${editing}` : URL;
    const method = editing ? 'PUT' : 'POST';

    const body = {
      name,
      email,
      item,
      paragraph,
      tlp,
    };

    fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        if (editing) {
          setHeaders(
            headers.map((header) =>
              header.id === editing ? { ...header, ...body } : header
            )
          );
        } else {
          setHeaders([...headers, data]);
        }
        resetForm();
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  // Handle edit button click
  const handleEdit = (id) => {
    const header = headers.find((header) => header.id === id);
    setName(header.name);
    setEmail(header.email);
    setItem(header.item);
    setParagraph(header.paragraph);
    setTlp(header.tlp);
    setEditing(id);
  };

  // Handle delete button click
  const handleDelete = (id) => {
    setLoading(true);
    fetch(`${URL}/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        setHeaders(headers.filter((header) => header.id !== id));
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  // Function to export data to Excel
  const exportToExcel = () => {
    const data = headers.map((header) => ({
      Name: header.name,
      Email: header.email,
      Item: header.item,
      Paragraph: header.paragraph,
      Phone: header.tlp,
    }));
  
    // Menyusun worksheet dari data
    const ws = XLSX.utils.json_to_sheet(data);
  
    // Menambahkan border ke setiap cell
    const range = XLSX.utils.decode_range(ws['!ref']); // Mengambil range sheet
  
    // Define border style
    const borderStyle = {
      top: { style: 'thin', color: { rgb: "000000" } },
      left: { style: 'thin', color: { rgb: "000000" } },
      bottom: { style: 'thin', color: { rgb: "000000" } },
      right: { style: 'thin', color: { rgb: "000000" } }
    };
  
    // Loop untuk memberikan border ke setiap cell dalam range
    for (let row = range.s.r; row <= range.e.r; row++) {
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cellAddress = { r: row, c: col };
        const cellRef = XLSX.utils.encode_cell(cellAddress);
        
        // Jika cell tidak ada, buat cell kosong
        if (!ws[cellRef]) {
          ws[cellRef] = { t: 's', v: '' }; // Menyimpan data cell kosong
        }
        
        // Menambahkan border ke cell
        if (!ws[cellRef].s) {
          ws[cellRef].s = {}; // Pastikan ada style yang diterapkan
        }
  
        // Menambahkan border ke setiap cell
        ws[cellRef].s.border = borderStyle; // Set border style
      }
    }
  
    // Menambahkan worksheet ke workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Memo List');
  
    // Menyimpan file Excel
    XLSX.writeFile(wb, 'memo_list_with_borders.xlsx');
  };
  // Reset form fields
  const resetForm = () => {
    setName('');
    setEmail('');
    setItem('');
    setParagraph('');
    setTlp('');
    setEditing(null);
  };

  if (loading) {
    return <div className={styles.loader}>Loading...</div>;
  }

  if (error) {
    return <div className={styles.error}>Error: {error.message}</div>;
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Penewaran list</h1>

      {editing && (
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.formGroup}>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={handleChangeName}
              placeholder="Enter name"
              className={styles.input}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleChangeEmail}
              placeholder="Enter email"
              className={styles.input}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="item">Item</label>
            <input
              type="text"
              id="item"
              value={item}
              onChange={handleChangeItem}
              placeholder="Enter item"
              className={styles.input}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="paragraph">Paragraph</label>
            <textarea
              id="paragraph"
              value={paragraph}
              onChange={handleChangeParagraph}
              placeholder="Enter paragraph"
              className={styles.textarea}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="tlp">Phone</label>
            <input
              type="text"
              id="tlp"
              value={tlp}
              onChange={handleChangeTlp}
              placeholder="Enter phone number"
              className={styles.input}
            />
          </div>

          <button type="submit" className={styles.submitButton}>
            {editing ? 'Update Memo' : 'Add Memo'}
          </button>
        </form>
      )}

      <h3 className={styles.subheader}>Penawaran list</h3>
      <button onClick={exportToExcel} className={styles.exportButton}>
        Download Excel
      </button>

      <ul className={styles.list}>
        {headers.map((header) => (
          <li key={header.id} className={styles.listItem}>
            <div className={styles.textContainer}>
              <p className={styles.listParagraph}>{header.name}</p>
              <p className={styles.listParagraph}>{header.email}</p>
              <p className={styles.listParagraph}>{header.item}</p>
              <p className={styles.listParagraph}>{header.paragraph}</p>
              <p className={styles.listParagraph}>{header.tlp}</p>
            </div>
            <div className={styles.actions}>
              <button
                onClick={() => handleEdit(header.id)}
                className={`${styles.actionButton} ${styles.editButton}`}
              >
                Edit
              </button>
              <button
                onClick={() => handleDelete(header.id)}
                className={`${styles.actionButton} ${styles.deleteButton}`}
              >
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Memo;
