import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
            <div className="col-md-8 col-md-offset-2 intro-text">
  {props.data?.img ? (
    <img src={props.data.img} className="img-responsive" alt={props.data.title} />
  ) : (
    <p>Loading...</p>  
  )}
  <h1>
    {/* <span>{props.data?.title}</span> */}
  </h1>
  <a href="#features"  className="btn btn-custom btn-lg page-scroll">
    Learn More
  </a>
</div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
