import React, { useState, useEffect } from 'react';
import styles from './css/Header.module.css'; // Make sure the CSS file exists

const Contact = () => {
  const URL = process.env.REACT_APP_API_URL;

  const [headers, setHeaders] = useState([]);
  const [paragraph, setParagraph] = useState('');
  const [paragraph1, setParagraph1] = useState('');
  const [paragraph2, setParagraph2] = useState('');
  const [paragraph3, setParagraph3] = useState('');
  const [paragraph4, setParagraph4] = useState('');
  const [paragraph5, setParagraph5] = useState('');
  const [paragraph6, setParagraph6] = useState('');
  const [previewImage, setPreviewImage] = useState(null); // Image preview
  const [editing, setEditing] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state

  // Fetch data for headers
  useEffect(() => {
    loadData()
  }, []);
  const loadData = () => {
    setLoading(true);
    fetch(URL + '/api/contact')
      .then((response) => response.json())
      .then((data) => {
        setHeaders(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }

  // Handle text area change
  const handleChange = (e) => {
    setParagraph(e.target.value);
  };
  const handleChange1 = (e) => {
    setParagraph1(e.target.value);
  };
  const handleChange2 = (e) => {
    setParagraph2(e.target.value);
  };
  const handleChange3 = (e) => {
    setParagraph3(e.target.value);
  };
  const handleChange4 = (e) => {
    setParagraph4(e.target.value);
  };
  const handleChange5 = (e) => {
    setParagraph5(e.target.value);
  };
  const handleChange6 = (e) => {
    setParagraph6(e.target.value);
  };

  // Handle file input change for image preview
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle form submit (Add or Edit)
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const url = editing ? `${URL}/api/contact/${editing}` : `${URL}/api/contact`;
    const method = editing ? 'PUT' : 'POST';

    const body = { 
      address:paragraph,
      phone:paragraph1,
      email:paragraph2,
      facebook:paragraph3,
      instagram:paragraph4,
      twitter:paragraph5,
      youtube:paragraph6,
     };
    if (previewImage) {
      body.image = previewImage; // Assuming image needs to be included in the request
    }

    fetch(url, {
      method: method,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        loadData();

        if (editing) {
          setHeaders(headers.map((header) => (header.id === editing ? { ...header, paragraph } : header)));
        } else {
          setHeaders([...headers, data.data]);
        }
        setParagraph('');
        setPreviewImage(null); // Reset image preview
        setEditing(null); // Reset editing mode
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  // Handle edit button click
  const handleEdit = (id) => {
    const header = headers.find((header) => header.id === id);
    setParagraph(header.address);
    setParagraph1(header.phone);
    setParagraph2(header.email);
    setParagraph3(header.facebook);
    setParagraph4(header.instagram);
    setParagraph5(header.twitter);
    setParagraph6(header.youtube);
    setEditing(id);
  };

  // Handle delete button click
  const handleDelete = (id) => {
    setLoading(true);
    fetch(`${URL}/api/contact/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        setHeaders(headers.filter((header) => header.id !== id));
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  if (loading) {
    return <div className={styles.loader}>Loading...</div>; // Display loader while fetching data
  }

  if (error) {
    return <div className={styles.error}>Error: {error.message}</div>; // Display error message if fetch fails
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Contact CROYONS</h1>
{editing&&<>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formGroup}>
          <label htmlFor="paragraph">address</label>
          <textarea 
            name="paragraph"
            id="paragraph"
            value={paragraph}
            onChange={handleChange}
            placeholder="Enter paragraph"
            className={styles.textarea1}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="paragraph">phone</label>
          <textarea
            name="paragraph"
            id="paragraph"
            value={paragraph1}
            onChange={handleChange1}
            placeholder="Enter paragraph"
            className={styles.textarea1}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="paragraph">email</label>
          <textarea
            name="paragraph"
            id="paragraph"
            value={paragraph2}
            onChange={handleChange2}
            placeholder="Enter paragraph"
            className={styles.textarea1}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="paragraph">facebook</label>
          <textarea
            name="paragraph"
            id="paragraph"
            value={paragraph3}
            onChange={handleChange3}
            placeholder="Enter paragraph"
            className={styles.textarea1}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="paragraph">instagram</label>
          <textarea
            name="paragraph"
            id="paragraph"
            value={paragraph4}
            onChange={handleChange4}
            placeholder="Enter paragraph"
            className={styles.textarea1}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="paragraph">twitter</label>
          <textarea
            name="paragraph"
            id="paragraph"
            value={paragraph5}
            onChange={handleChange5}
            placeholder="Enter paragraph"
            className={styles.textarea1}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="paragraph">youtube</label>
          <textarea
            name="paragraph"
            id="paragraph"
            value={paragraph6}
            onChange={handleChange6}
            placeholder="Enter paragraph"
            className={styles.textarea1}
          />
        </div>

        <div className={styles.formGroup}>
         
        </div>
        <button type="submit" className={styles.submitButton}>
          {editing ? 'Update Header' : 'Add Header'}
        </button>
      </form>
</>
}

      <h3 className={styles.subheader}>Existing Data</h3>
      <ul className={styles.list}>
        {headers.map((header) => (
          <li key={header.id} className={styles.listItem}>
            <div className={styles.textContainer}>
              <p className={styles.listParagraph}>{header.address}</p>
              <p className={styles.listParagraph}>{header.phone}</p>
              <p className={styles.listParagraph}>{header.email}</p>
              <p className={styles.listParagraph}>{header.facebook}</p>
              <p className={styles.listParagraph}>{header.instagram}</p>
              <p className={styles.listParagraph}>{header.twitter}</p>
              <p className={styles.listParagraph}>{header.youtube}</p>
            </div>
            <div className={styles.actions}>
              <button
                onClick={() => handleEdit(header.id)}
                className={`${styles.actionButton} ${styles.editButton}`}
              >
                Edit
              </button>
              {/* <button
                onClick={() => handleDelete(header.id)}
                className={`${styles.actionButton} ${styles.deleteButton}`}
              >
                Delete
              </button> */}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Contact;
