import React, { useState, useEffect } from 'react';
import styles from './css/Header.module.css'; // Make sure the CSS file exists
import { useNavigate } from "react-router-dom";

const TestimonialHeader = () => {
  const URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [headers, setHeaders] = useState([]);
  const [paragraph, setParagraph] = useState('');
  const [previewImage, setPreviewImage] = useState(null); // Image preview
  const [editing, setEditing] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state

  // Fetch data for headers
  useEffect(() => {
    loadData();
    checkAuth();
  }, []);
const checkAuth =() => {
  fetch(URL + `/api/aboutdetail/cek`,{
      headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.message === "Invalid or expired token"){
          console.log("wkwkwkkw");
          navigate("/admin");
      }
    })
    .catch((error) => console.log(error));
}
  const loadData = () => {
    setLoading(true);
    fetch(URL + '/api/testimonialheader')
      .then((response) => response.json())
      .then((data) => {
        setHeaders(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }
  // Handle text area change
  const handleChange = (e) => {
    setParagraph(e.target.value);
  };

  // Handle file input change for image preview
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle form submit (Add or Edit)
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const url = editing ? `${URL}/api/testimonialheader/${editing}` : `${URL}/api/testimonialheader`;
    const method = editing ? 'PUT' : 'POST';

    const body = { paragraph };
    if (previewImage) {
      body.image = previewImage; // Assuming image needs to be included in the request
    }

    fetch(url, {
      method: method,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        if (editing) {
          setHeaders(headers.map((header) => (header.id === editing ? { ...header, paragraph } : header)));
        } else {
          setHeaders([...headers, data.data]);
        }
        setParagraph('');
        setPreviewImage(null); // Reset image preview
        setEditing(null); // Reset editing mode
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  // Handle edit button click
  const handleEdit = (id) => {
    const header = headers.find((header) => header.id === id);
    setParagraph(header.paragraph);
    setEditing(id);
  };

  // Handle delete button click
  const handleDelete = (id) => {
    const isConfirmed = window.confirm("Apakah Anda yakin ingin menghapus data ini?");
    
    if (isConfirmed) {

      setLoading(true);
      fetch(`${URL}/api/testimonialheader/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json',
        },
      })
        .then(() => {
          setHeaders(headers.filter((header) => header.id !== id));
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
  };

  if (loading) {
    return <div className={styles.loader}>Loading...</div>; // Display loader while fetching data
  }

  if (error) {
    return <div className={styles.error}>Error: {error.message}</div>; // Display error message if fetch fails
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>About Portofolio</h1>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formGroup}>
          <label htmlFor="paragraph">Paragraph</label>
          <textarea
            name="paragraph"
            id="paragraph"
            value={paragraph}
            onChange={handleChange}
            placeholder="Enter paragraph"
            className={styles.textarea}
          />
        </div>

        <div className={styles.formGroup}>
          {/* <label htmlFor="img">Image</label>
          <input
            type="file"
            name="img"
            id="img"
            onChange={handleFileChange}
            className={styles.fileInput}
          /> */}
          {/* {previewImage && (
            <div className={styles.previewContainer}>
              <h4>Image Preview:</h4>
              <img src={previewImage} alt="Preview" className={styles.previewImage} />
            </div>
          )} */}
        </div>
{editing&&
        <button type="submit" className={styles.submitButton}>
          {editing ? 'Update Header' : 'Add Header'}
        </button>
}
      </form>

      <h3 className={styles.subheader}>Existing Data</h3>
      <ul className={styles.list}>
        {headers.map((header) => (
          <li key={header.id} className={styles.listItem}>
            <div className={styles.textContainer}>
              <p className={styles.listParagraph}>{header.paragraph}</p>
            </div>
            <div className={styles.actions}>
              <button
                onClick={() => handleEdit(header.id)}
                className={`${styles.actionButton} ${styles.editButton}`}
              >
                Edit
              </button>
              {/* <button
                onClick={() => handleDelete(header.id)}
                className={`${styles.actionButton} ${styles.deleteButton}`}
              >
                Delete
              </button> */}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TestimonialHeader;
